import "react-phone-input-2/lib/bootstrap.css";
import "bootstrap/dist/css/bootstrap.css";
import "moment/locale/es";
import "./CouponQR.css";

import { Card, Row } from "react-bootstrap";
import React, { Component } from "react";

import PRISMA_POST from "../../common/requests";
import QRCode from "qrcode.react";
import moment from "moment";

class CouponQR extends Component {
  state = {
    loading: true,
    exists: false,
    title: "Coupon Not Found",
    description: "This coupon doesn't appear to be registered in our system",
    logoURL: "",
    organizationName: "",
    clientName: "",
    expiration: "",
  };

  async componentDidMount() {
    const hash = this.props.match.params.hash;

    const query = `
          query {
              clientCouponByHash(hash: "${hash}"){
                  contact{
                      name
                      organization {
                          name
                      }
                  }
                  coupon{
                      id
                      title
                      description
                      expiration
                      active
                      logoURL
                  }
              }
            }`;

    let cMutationResponse = await PRISMA_POST(query);
    if (
      cMutationResponse.status < 300 &&
      cMutationResponse.data.data.clientCouponByHash
    ) {
      const clientCoupon = cMutationResponse.data.data.clientCouponByHash;
      this.setState({
        title: clientCoupon.coupon.title,
        description: clientCoupon.coupon.description,
        logoURL: clientCoupon.coupon.logoURL,
        expiration: clientCoupon.coupon.expiration,
        organizationName: clientCoupon.contact.organization.name,
        clientName: clientCoupon.contact.name,
        loading: false,
        exists: true,
      });
    } else {
      this.setState({ loading: false });
    }
  }

  formatDate = (date) => {
    const fd = moment(date).locale("es").utc().format("DD [de] MMMM [de] YYYY");
    if (fd !== "Invalid date") return `Válido hasta ${fd}.`;
  };

  render() {
    if (this.state.loading) {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Card className="p-4 shadow lw-card">
            <Card.Title className="text-center">Loading...</Card.Title>
            <hr />
            <Card.Subtitle className="text-muted text-center">
              Please wait
            </Card.Subtitle>
          </Card>
        </div>
      );
    }
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          marginTop: "2rem",
        }}
      >
        <Card className="p-4 shadow qr-card">
          {!!this.state.logoURL ? (
            <>
              <Row className="center" style={{ padding: "10px" }}>
                <img className="logo" src={this.state.logoURL} alt="logo-img" />
              </Row>
              <hr />
            </>
          ) : (
            <></>
          )}
          <Card.Body>
            <Card.Title className="text-center title-container">
              {this.state.title}
            </Card.Title>
            <div className="text-muted text-center description-container">
              {this.state.description}
            </div>
            {this.state.exists && (
              <>
                <Row className="justify-content-center qr-hash-container">
                  <Row className="justify-content-center qr-container">
                    {this.state.organizationName && (
                      <QRCode
                        id="qe"
                        size={200}
                        value={`https://${process.env.REACT_APP_LEAD_WIRE_LINK}/vc/${this.props.match.params.hash}`}
                        style={{ padding: 0 }}
                      />
                    )}
                  </Row>
                  <Row className="justify-content-center hash-container">
                    {this.props.match.params.hash}
                  </Row>
                </Row>
                <div className="text-muted text-center date-name-container">
                  {!!this.state.expiration ? (
                    <span>{this.formatDate(this.state.expiration)}</span>
                  ) : (
                    <></>
                  )}
                  {!!this.state.clientName &&
                  this.state.clientName !== "N/A" ? (
                    <span>Nombre: {this.state.clientName}</span>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="text-muted text-center redeem-note-container">
                  {`Presenta este cupón en ${this.state.organizationName} para redimir!`}
                </div>
              </>
            )}
          </Card.Body>
        </Card>
      </div>
    );
  }
}

export default CouponQR;
