// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.img {
    display: flex; 
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-direction: column;
    width: 30%;
    min-width: 130px;
}
`, "",{"version":3,"sources":["webpack://./src/views/MainPage/MainPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,qBAAqB;IACrB,mBAAmB;IACnB,sBAAsB;IACtB,UAAU;IACV,gBAAgB;AACpB","sourcesContent":[".img {\n    display: flex; \n    justify-content: center;\n    align-content: center;\n    align-items: center;\n    flex-direction: column;\n    width: 30%;\n    min-width: 130px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
